import { get, pick, union ,omit} from 'lodash';

import { useSelector } from 'react-redux';
import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';

import {
  createPromiseAction,
  rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';
import {
  Tenant, User, TenantActionProps, ContextUser
} from '../types';
import { sanitize } from '@ivymobi/orbit-domain';
import { AnyAction } from 'redux';

const defaultCampaigns = {ids: [], data: {}, stats: {}};

export const useCampaigns = (user: User, tenant: Tenant) => useSelector(
  (state) => {
    return get(state, [user.id, 'tenants', tenant.id, 'campaigns'], defaultCampaigns);
  }
);

export const deleteCampaignAction = createPromiseAction(
  'campaign/DELETE_CAMPAIGN',
  data => pick(data, ['tenantId', 'campid']),
  data => pick(data, [ 'user' ]),
);
function* watchDeleteCampaign(api) {
  
  yield takeLeading(deleteCampaignAction, function* (action) {
   
    const {user: {token, user} } = action.meta;
    try {
      const res = yield call(api.deleteJSON, {
        url: `tenants/${action.payload.tenantId}/${action.payload.campid}/deleteCampaign`,
        token,
        body: {}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const getCampaignsAction = createPromiseAction(
  'campaign/LIST',
  () => ({}),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchGetCampaigns(api) {
  yield takeLeading(getCampaignsAction, function* (action) {
    const {meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/campaigns`,
        token: token,
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getStatProductsAction = createPromiseAction(
  'campaign/PRODUCTS_STAT_LIST',
  () => ({}),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchGetStatProducts(api) {
  yield takeLeading(getStatProductsAction, function* (action) {
    const {meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/products`,
        token: token,
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getStatArticlesAction = createPromiseAction(
  'campaign/ARTICELS_STAT_LIST',
  () => ({}),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchGetStatArticles(api) {
  yield takeLeading(getStatArticlesAction, function* (action) {
    const {meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/article`,
        token: token,
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const createCampaignAction = createPromiseAction(
  'campaign/CREATE',
  (data) => data.payload,
  (data) => pick(data, ['user', 'tenant'])
);

function* watchCreateCampaign(api) {
  yield takeLeading(createCampaignAction, function* (action: TenantActionProps) {
    const { payload, meta: { user, tenant } } = action;
    try {
      const showcase = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/campaigns`,
        token: user.token,
        body: {tenantId: tenant.id, ...payload},
      });
      yield call(resolvePromiseAction, action, showcase);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * 修改活动设置。
 * @param id 活动ID
 * @param settings 分享新的设置
 * @param user
 * @param tenant
 */
export const reconfigCampaignAction = createPromiseAction(
  'campaign/RECONFIG',
  (data) => data.payload,
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchReconfigCampaign(api) {
  yield takeLeading(reconfigCampaignAction, function* (action) {
    const { payload, meta: { user: {token, user}, tenant } } = action;
    try {
      const campaign = yield call(api.putJSON, {
        url: `tenants/${tenant.id}/campaigns/${payload.id}/settings`,
        token,
        body: payload
      });
      // campaign = yield sanitize.share(campaign);
      yield call(resolvePromiseAction, action, campaign, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// export const getCampaignStatsAction = createPromiseAction(
//   'campaign/GET_STATS',
//   data => data.payload,
//   (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
// );
// function* watchGetCampaignStats(api) {
//   yield takeLeading(getCampaignStatsAction, function* (action) {
//     const {user: {token, user}, tenant} = action.meta;
//     try {
//       const stats = yield call(api.getJSON, {
//         url: `tenants/${tenant.id}/campaigns/${action.payload}/stats`,
//         token
//       });

//       yield call(resolvePromiseAction, action, stats,
//         { showcaseId: action.payload, user, tenant}
//       );
//     } catch (error) {
//       yield call(rejectPromiseAction, action, error);
//     }
//   });
// }

export const getCampaignLead = createPromiseAction(
  'campaign/GET_LEAD',
  data => pick(data, ['showcaseId', 'id']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchGetCampaignLead(api) {
  yield takeEvery(getCampaignLead, function* (action) {
    const { payload, meta: {tenant, user: {token, user}} } = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/campaigns/${payload.showcaseId}/leads/${payload.id}`,
        token,
      });

      yield call(resolvePromiseAction, action, res,
        { user, tenant, campaignId: payload.showcaseId }
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const reducer = handleActions({
  [createCampaignAction.resolved.toString()]: (state, {action: {payload: showcase}}) => {
    const ids = union([showcase.id], state.ids);
    const data = {...state.data, [showcase.id]: showcase, leads: {}, documents: {}};
    return {ids, data};
  },
  [reconfigCampaignAction.resolved.toString()]: (state, {payload: campaign}) => {
    return {
      ...state,
      data: {
        ...state.data,
        [campaign.id]: campaign
      }
    };
  },
  [deleteCampaignAction.resolved.toString()]: (state, {payload}) => {

    return omit(state.data, payload.campid); 
  },
  [getCampaignsAction.resolved.toString()]: (state, {payload}) => {
    const ids = payload.map(campaign => campaign.id);
    const data = payload.reduce((prev, campaign) => {
      prev[campaign.id] = campaign;
      return prev;
    }, {});

    return {
      ...state,
      ids, data
    };
  },
  // [getCampaignStatsAction.resolved.toString()]: (state, action) => {
  //   const { id } = action.meta;
  //   const stats = action.payload;
  //   return {
  //     ...state,
  //     stats: {
  //       ...state.stats,
  //       [id]: stats
  //     }
  //   };
  // },
  [getCampaignLead.resolved.toString()]: (state, {meta: {campaignId}, payload: lead}) => {
    // TODO
    return state;
    // let campaign = state.data[campaignId];
    // if (!campaign) { return state; }
    // return {
    //   ...state,
    //   data: {
    //     ...state.data,
    //     [campaignId]: {
    //       ...campaign,

    //     }
    //   }
    //   [showcaseId]: {
    //     leads: {
    //       ...state[showcaseId].leads,
    //       [action.payload.id]: action.payload,
    //     }
    //   }
    // };
  },
}, defaultCampaigns);

export const watchers = [
  watchGetCampaigns, watchCreateCampaign, watchReconfigCampaign,
  watchGetCampaignLead, watchDeleteCampaign,watchGetStatProducts,watchGetStatArticles,
];

