import { useSelector } from 'react-redux';
import { all, call, takeEvery } from 'redux-saga/effects';

import {
  createPromiseAction,
  resolvePromiseAction,
} from '../util/redux-saga-promise';

type AlertColor = 'info' | 'success' | 'warning' | 'error'
interface NotificationState {
  type: AlertColor;
  message: string;
  autoHideDuration?: number;
}

const SHOW_NOTIFICATION = 'notification/SHOW';
export const hideNotificationAction = createPromiseAction('notification/HIDE');

export const showNotification = (message, type = 'info', duration = 4000) => ({
  type: SHOW_NOTIFICATION,
  payload: {
    message,
    type,
    autoHideDuration: duration
  }
});

function* watchHideNotify() {
  yield takeEvery(hideNotificationAction, function* (action) {
    yield call(resolvePromiseAction, action);
  });
}

export function* notificationSaga () {
  yield all([
    call(watchHideNotify)
  ]);
}

export const notificationReducer = function(state: NotificationState, action) {
  if (action.type === hideNotificationAction.resolved.toString()) {
    return null;
  }

  if (action.type === SHOW_NOTIFICATION) {
    return {
      type: action.payload.type,
      message: action.payload.message,
      autoHideDuration: action.payload.autoHideDuration
    };
  }

  if (!action.meta || !action.meta.notify) {
    return state;
  }

  if (action.type.endsWith('.REJECTED')) {
    return {
      type: 'error',
      message: action.meta.notify.message || action.payload.message
    };
  }

  if (action.type.endsWith('.RESOLVED')) {
    return {
      type: 'success',
      message: action.meta.notify.message
    };
  }

  return state;
};

export const notifyReducer = function(message) {
  return {
    type: 'success',
    message: message
  };
};

export const useNotification = () => useSelector<
  { notification: NotificationState }, NotificationState
>(state => {
  return state.notification;
});
