import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { promiseMiddleware } from '../util/redux-saga-promise';
import { userReducer, userSaga } from './user';

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (traceOptions: {
    trace: boolean;
    traceLimit: number;
  }) => (<R>(a: R) => R);
}

const configureStore = (api, initialState = {}, customSaga?) => {
  const typedWindow = typeof window !== 'undefined' && (window as Window);
  const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
      typedWindow !== 'undefined' &&
      typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
  compose;
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    userReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(promiseMiddleware, sagaMiddleware),
    )
  );
  sagaMiddleware.run(customSaga || userSaga, api);
  return store;
};

export default configureStore;
