import _, { difference, get, omit, pick } from 'lodash';
import { useSelector } from 'react-redux';
import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';

import {
  createPromiseAction,
  rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';
import { Tenant, User } from '../types';
import { sanitize } from '@ivymobi/orbit-domain';
import { AnyAction } from 'redux';

const defaultStates = { ids: [], data: {} };




export const useFavorites = (user: User, tenant: Tenant) => useSelector(
  (state) => get(state, [user.id, 'tenants', tenant.id, 'favorites'], defaultStates)
);

export const useFavorite = (id: string, user: User, tenant: Tenant) =>
  useSelector(
    (state) => get(state, [user.id, 'tenants', tenant.id, 'favorites', 'data', id], undefined)
  );


//更新收藏
export const sortCollectionDocumentAction = createPromiseAction(
  'document/SORT_COLLECTION_UPDATE',
  data => data.payload,
  data => pick(data, ['tenant', 'user']),
);
function* watchSortCollectionDocument(api) {
  yield takeEvery(sortCollectionDocumentAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/collections/${action.payload.id}/update`;
      let fav  = yield call(api.postJSON, {
        url,
        token,
        body: action.payload,
      });
      yield call(resolvePromiseAction, action, fav, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const createFavoriteAction = createPromiseAction(
  'favorite/CREATE',
  data => pick(data, ['name']),
  data => pick(data, ['tenant', 'user']),
);

export const deleteFavoriteAction = createPromiseAction(
  'favorite/DELETE',
  data => pick(data, ['collectionId']),
  data => pick(data, ['tenant', 'user', 'item']),
);

// web版
export const updateFavoriteAction = createPromiseAction(
  'favorite/UPDATE',
  data => pick(data, ['collection']),
  data => pick(data, ['tenant', 'user']),
);

// 小程序
export const updateFavoriteActionSmall = createPromiseAction(
  'favorite/UPDATE_SMALL',
  data => pick(data, ['collection']),
  data => pick(data, ['tenant', 'user', 'item']),
);

export const getFavoritesAction = createPromiseAction(
  'favorite/GET_LIST',
  () => ({}),
  data => pick(data, ['tenant', 'user'])
);

export const getPersonalDocumentsList = createPromiseAction(
  'favorite/PERSON_LIST',
  data => pick(data, ['collection']),
  data => pick(data, ['tenant', 'user', 'item']),
);

export const deletePersonalDocumentsList = createPromiseAction(
  'favorite/DELPERSON_LIST',
  data => pick(data, ['collection']),
  data => pick(data, ['tenant', 'id', 'user']),
);

export const collectPersonalDocumentsList = createPromiseAction(
  'favorite/COLLECTPERSON_LIST',
  data => pick(data, ['collection']),
  data => pick(data, ['tenant', 'id', 'user', 'fileData', 'collected']),
);

function* watchGetFavorites(api) {
  yield takeLeading<AnyAction>(getFavoritesAction, function* (action) {
    const { tenant, user } = action.meta;
    try {
      let favorites = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/favorites`,
        token: user.token,
      });
      favorites = yield sanitize.favoriteList(favorites);
      // const folderImg = _.get(tenant, ['info', 'companyCoverCurrentUrl'], null);
      // favorites.forEach((item) => {
      //   item.thumbnail = folderImg;
      // });
      yield call(resolvePromiseAction, action, favorites, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

function* watchCreateFavorite(api) {
  yield takeEvery<AnyAction>(createFavoriteAction, function* (action) {
    const { tenant, user } = action.meta;
    try {
      let newCollection = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/favorites`,
        token: user.token,
        body: { name: action.payload.name }
      });
      newCollection = yield sanitize.favorite(newCollection);
      yield call(resolvePromiseAction, action, newCollection, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

function* watchDeleteFavorite(api) {
  yield takeEvery<AnyAction>(deleteFavoriteAction, function* (action) {
    const { tenant, user: { token, user } } = action.meta;
    try {
      yield call(api.deleteJSON, {
        url: `tenants/${tenant.id}/favorites/${action.payload.collectionId}`,
        token,
        body: {}
      });

      yield call(resolvePromiseAction, action, action.payload.collectionId, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// web接口
function* watchUpdateFavorite(api) {
  yield takeEvery<AnyAction>(updateFavoriteAction, function* (action) {
    const { tenant, user } = action.meta;
    const { collection } = action.payload;

    try {
      yield call(api.putJSON, {
        url: `tenants/${tenant.id}/favorites/${collection.id}`,
        token: user.token,
        body: collection
      });

      yield call(resolvePromiseAction, action, collection, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 小程序接口
function* watchUpdateFavoriteSmall(api) {
  yield takeEvery<AnyAction>(updateFavoriteActionSmall, function* (action) {
    const { tenant, user } = action.meta;
    const { collection } = action.payload;
    try {
      yield call(api.putJSON, {
        url: `tenants/${tenant.id}/favorites/${collection.id}`,
        token: user.token,
        body: {
          name: collection.name,
          documents: [...collection.documents], //取消收藏时传空数组, 更新收藏时传docId的字符串
          createdAt: new Date().toJSON(),
          updatedAt: collection.updatedAt
        }
      });

      yield call(resolvePromiseAction, action, collection, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取个人上传数据列表
function* watchGetPersonalDocumentsList(api) {
  yield takeEvery<AnyAction>(getPersonalDocumentsList, function* (action) {
    const { user: { user, token }, tenant } = action.meta;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/getPersonalDocumentsList`,
        token,
        body: {}
      });

      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 删除个人上传文件
function* watchDeletePersonalDocumentsList(api) {
  yield takeEvery<AnyAction>(deletePersonalDocumentsList, function* (action) {
    const { tenant, id, user } = action.meta;
    try {
      const res = yield call(api.deleteJSON, {
        url: `tenants/${tenant.id}/personalDocuments/${id.id}`,
        token: user.token,
        body: {}
      });

      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 个人文件收藏
function* watchCollectPersonalDocumentsList(api) {
  yield takeEvery<AnyAction>(collectPersonalDocumentsList, function* (action) {
    const { tenant, id, user, fileData, collected } = action.meta;
    try {
      const res = yield call(api.putJSON, {
        url: `tenants/${tenant.id}/favorites/${fileData.id}`,
        token: user.token,
        body: {
          name: fileData.name,
          documents: collected ? [id, ...fileData.documents] : [], //取消收藏时传空数组, 更新收藏时传docId的字符串
          createdAt: new Date().toJSON(),
          updatedAt: fileData.updatedAt
        }
      });

      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const reducer = handleActions({
  [sortCollectionDocumentAction.resolved]:(state, { payload }) => {
    let dat = payload.data.map(item=>{item.id=item.coc_id;return item})
    const data = dat.reduce((prev, c) => ({
      ...prev,
      [c.id]: c
    }), {});
    const ids = payload.data?.documents || [];
    return {ids, data};
  },
  [createFavoriteAction.resolved]: (state, { payload }) => {
    const ids = [payload.id].concat(state.ids);
    const data = { ...state.data, [payload.id]: payload };
    return { ids, data };
  },

  [getFavoritesAction.resolved]: (state, { payload }) => {
    const ids = (payload || []).map(item => item.id);
    const data = (payload || []).reduce((prev, c) => ({
      ...prev,
      [c.id]: c
    }), {});
    return { ids, data };
  },

  [deleteFavoriteAction.resolved]: (state, { payload }) => {
    const ids = difference(state.ids, [payload]);
    const data = omit(state.data, payload);
    return { ids, data };
  },

  // web
  [updateFavoriteAction.resolved]: (state, { payload }) => {
    const data = { ...state.data, [payload.id]: payload };
    return { ...state, data };
  },

  // 小程序
  [updateFavoriteActionSmall.resolved]: (state, { payload }) => {
    const data = { ...state.data, [payload.id]: payload };
    return { ...state, data };
  },

  [getPersonalDocumentsList.resolved]: (state, { payload }) => {
    const data = { ...state.data, [payload.id]: payload };
    return { ...state, data };
  },

  [deletePersonalDocumentsList.resolved]: (state, { payload }) => {
    const data = { ...state.data, [payload.id]: payload };
    return { ...state, data };
  },

  [collectPersonalDocumentsList.resolved]: (state, { payload }) => {
    const data = { ...state.data, [payload.id]: payload };
    return { ...state, data };
  },
}, defaultStates);

export const watchers = [
  watchCreateFavorite, watchDeleteFavorite, watchGetFavorites,watchSortCollectionDocument,
  watchUpdateFavoriteSmall,
  watchUpdateFavorite,
  watchGetPersonalDocumentsList,
  watchDeletePersonalDocumentsList,
  watchCollectPersonalDocumentsList
];
