import { isEmpty, get, pick, union, omit} from 'lodash';

import { useSelector } from 'react-redux';
import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';

import {
  createPromiseAction,
  rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';
import {
  Tenant, ContextUser
} from '../types';

const defaultCampaigns = { data: [], detail: {}, type: [] };
export const useLibrary = ({user, tenant}) => useSelector(
  (state) => {
    const uid = get(user, ['user', 'id'], '');
    return get(state, [uid, 'tenants', tenant.id, 'library', 'data'], []);
  }
);
export const useLibraryDetail = ({user, tenant}) => useSelector(
  (state) => {
    const uid = get(user, ['user', 'id'], '');
    return get(state, [uid, 'tenants', tenant.id, 'library', 'detail'], []);
  }
);

// 更新文件
export const updateMetaFileAction = createPromiseAction(
  'library/updateMetaFileAction',
  (data) => pick(data, ['metadataId']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchUpdateMetaFileAction(api) {
  yield takeLeading(updateMetaFileAction, function* (action) {
    const {payload: {metadataId}, meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/getFilesFromLibraryNewDQ?tenantId=${tenant.id}&metadataId=${metadataId}`,
        token: token,
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//导入批量数据
export const importMetaDataAction = createPromiseAction(
  'library/IMPORT_MEATA_DATA',
  (data: { file }) => pick(data, ['file']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchImportMetaData(api) {
  yield takeLeading(importMetaDataAction, function* (action) {
    const { payload: { file }, meta: { user: { token, user }, tenant } } = action;
    try {
      const res = yield call(api.upload, {
        url: `tenants/batchSaveAbbMetadataDetail` ,
        token: token,
        tenantId: tenant.id,
        file: file,
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// library  获取/查询 元数据列表
export const getLibraryMetaDataAction = createPromiseAction(
  'library/LIST',
  (data) => pick(data, ['nameFilter']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchGetLibraryMetaData(api) {
  yield takeLeading(getLibraryMetaDataAction, function* (action) {
    const {payload: {nameFilter}, meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: isEmpty(nameFilter) ? `tenants/${tenant.id}/getMetadataList` : `tenants/${tenant.id}/getMetadataList?nameFilter=${nameFilter}`,
        token: token,
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// library  查询 元数据详情
export const getLibraryMetaDataDetailAction = createPromiseAction(
  'library/LISTDETAIL',
  (data) => pick(data, ['id']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchGetLibraryMetaDataDetail(api) {
  yield takeLeading(getLibraryMetaDataDetailAction, function* (action) {
    const {payload: {id}, meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/getMetadataDetail/${id}`,
        token: token,
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 新增metadata记录
export const newLibraryMetaDataAction = createPromiseAction(
  'price/ADDDETAIL',
  (data) => pick(data, ['metaDataInfo']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchNewLibraryData(api) {
  yield takeLeading(newLibraryMetaDataAction, function* (action) {
    const {payload: {metaDataInfo}, meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.putJSON, {
        url: `tenants/${tenant.id}/editMetadataDetail`,
        token: token,
        body: {
          metaDataInfo
        },
      });
      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 删除metadata记录
export const deleteLibraryMetaDataAction = createPromiseAction(
  'price/DELETEDETAIL',
  (data) => pick(data, ['id']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchDeleteLibraryData(api) {
  yield takeLeading(deleteLibraryMetaDataAction, function* (action) {
    const {payload: {id}, meta: {user: {token, user}, tenant}} = action;
    try {
      const res = yield call(api.deleteJSON, {
        url: `tenants/${tenant.id}/deleteMetadataDetail/${id}`,
        token: token,
        body: {
        },
      });
      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const reducer = handleActions({
  [getLibraryMetaDataAction.resolved.toString()]: (state, {payload}) => {
    const libraryList = isEmpty(payload) ? [] : [...payload];
    const lState = {...state, data: [...libraryList]};
    return lState;
  },
  // getLibraryMetaDataDetailAction
  [getLibraryMetaDataDetailAction.resolved.toString()]: (state, {payload}) => {
    const libraryDetailList = isEmpty(payload) ? {} : payload[0];
    const ldState = {...state, detail: {...libraryDetailList}};
    return ldState;
  },

}, defaultCampaigns);

export const watchers = [
  watchGetLibraryMetaData,
  watchImportMetaData,
  watchUpdateMetaFileAction,
  watchGetLibraryMetaDataDetail,
  watchNewLibraryData,
  watchDeleteLibraryData,
];
