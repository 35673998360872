import React from 'react';

export const UserContext = React.createContext(null);

export const TenantContext = React.createContext(null);

export const TeammessageContext = React.createContext(null);

export const WebSiteContext = React.createContext(null);

export const DeviceType = React.createContext('computer');

export const InnerUserContext = React.createContext(null);

export const ThemeContext = React.createContext(null);

export const IframeLoginContext = React.createContext(null);
