
import React, {
  lazy, Suspense, useCallback, useEffect, useState,
} from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter, Switch
} from 'react-router-dom';
import {
  Box, CssBaseline, ThemeProvider, createTheme,
} from '@mui/material';

import { logoutAction, resumeLastSessionAction, } from '@ivymobi/orbit-core/business/user';
import configureStore from '@ivymobi/orbit-core/business/configureStore';
import './services/i18n';
import * as api from './api';
import themeConfig from './services/theme';
import Loader from './components/loaders';
import { InnerUserContext, ThemeContext, UserContext, IframeLoginContext } from './orbit-context';
import { queryParse } from '@ivymobi/orbit-core';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const ActiveContainer = lazy(
  () => import('./ActiveContainer' /* webpackChunkName: "ActiveContainer" */)
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
  })
);

const Main = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const innerUser = queryParse(window.location.search) ?? {};
  const iframeLogin = window.self !== window.top;

  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch(resumeLastSessionAction()).then(
      (userData) => {
        userData.logout = logout;
        setUser(userData);
      },
      () => setUser({}));
  }, []);

  const doLogin = (userData) => {
    userData.logout = logout;
    setUser(userData);
  };

  const logout = useCallback(() => {
    dispatch(logoutAction())
    .then(() => {
      setUser({});
    });
  }, [dispatch, setUser]);

  return (
    <Box className={classes.root}>
      <UserContext.Provider value={user}>
        <InnerUserContext.Provider value={innerUser}>
          <IframeLoginContext.Provider value={iframeLogin}>
            <Switch>
              <ActiveContainer doLogin={doLogin} logout={logout}/>
            </Switch>
          </IframeLoginContext.Provider>
        </InnerUserContext.Provider>
      </UserContext.Provider>
    </Box>
  );
};

export default function OrbitApp() {
  const store = configureStore(api, {});

  themeConfig.palette.primary.main = '#005ae7';
  const theme = createTheme(themeConfig);

  const handleChangeTheme = (color) => {
    theme.palette.primary.main = color;
  };

  return (
    <BrowserRouter>
      <ThemeContext.Provider value={handleChangeTheme}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Suspense fallback={<Loader />} >
              <Main />
            </Suspense>
          </Provider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </BrowserRouter>
  );
}
