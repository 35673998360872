import { AnyAction, combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { all, call, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  filter, get, has, isEmpty, isNil, mapValues, mergeWith,
  omit, omitBy, pick, zipObject,
} from 'lodash';
import { useSelector } from 'react-redux';
import {
  createPromiseAction, rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';
import { sanitize } from '@ivymobi/orbit-domain';

import { ContextUser, Member, Share, Tenant, TenantActionMeta, User } from '../types';
import {
  reducer as documentsReducer, watchers as documentWatchers
} from './document';
import {
  reducer as outLibReducer, watchers as outLibWatchers
} from './outLibDocs';
import { reducer as productCenterReducer, watchers as productCenterWatchers } from './productCenter';
import {
  reducer as favoriteReducer, watchers as favoriteWatchers
} from './favorite';
import {
  getShareLeadAction,
  reducer as shareReducer, watchers as shareWatchers
} from './share';
import {
  reducer as campaignReducer, watchers as campaignWatchers
} from './campaign';
import {
  reducer as statsReducer, watchers as statsWatchers
} from './stats';

import {
  reducer as libraryReducer, watchers as libraryWatchers
} from './library';
import { getQuerysByArray } from '../util';

// import { getStatsAction, watchGetStats } from './stats';
// export { getStatsAction, useStats } from './stats';

// XXX 本质是DI，应该有更好的方式实现
let api = null;

// 获取pdf
// export const getPdfImagesAction = createPromiseAction (
//   'user_share/GetDocImage',
//   (data: {fileid: string}) => pick(data, ['fileid'])
// );

// export function* pdfImageList() {
//   yield takeEvery(getPdfImagesAction, function*(action) {
//     // debugger;
//     // const url = action.payload.url
//     // const token = action.payload.token
//     // const token = yield call(api.getItem, 'token');
//     // const option = {
//     //   'documentId': action.payload
//     // };
//     const {payload: {fileid}} = action;
//     try {
//       const res = yield call(api.getJSON, {
//         // url: `ShowCase/GetDocImage?documentId:${action.payload}`,
//         url: `shares/document/${fileid}/images`,
//         // params: option,
//         // token: action.payload.token
//       });
//       yield call(resolvePromiseAction, action, res);
//     } catch (error) {
//       yield call(rejectPromiseAction, action, error);
//     }

//   });
// }

//查询智能样本开启状态
export const getTemplateAction = createPromiseAction(
  'stats/GET_TEMPLATE_INFO',
  data => pick(data, ['tenantId']),
  data => pick(data, [ 'user' ]),
);
function* getTemplateInfo() {
  yield takeLeading<AnyAction>(getTemplateAction, function* (action) {
    const {user: {token} } = action.meta;
    try {
      const res = yield call(api.getJSON, {
        url: `sys/${action.payload.tenantId}/selTenantsECatalog`,
        token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/****************************
 * 智能样本相关就接口
 *
 *
 */
//查询样册详情
export const getSampleInfoAction = createPromiseAction(
  'tenant/GET_SAMPLE_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, member: Member }) => pick(data, ['user', 'tenant', 'member'])
);
function* watchSampleInfo() {
  yield takeLeading(getSampleInfoAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/selSampleInfo`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//查询模板2详情
export const getSample2InfoAction = createPromiseAction(
  'tenant/GET_SAMPLE__1221_2_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, member: Member }) => pick(data, ['user', 'tenant', 'member'])
);
export function* watchSample2Info(api) {
  yield takeLeading(getSample2InfoAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/selSample2Info`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//查询自定义按钮
export const getSampleInfoButtonAction = createPromiseAction(
  'tenant/GET_SAMPLE_BUTTON_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, language: string }) => pick(data, ['user', 'tenant', 'language'])
);
function* watchSampleButtonInfo() {
  yield takeLeading(getSampleInfoButtonAction, function* (action) {
    try {
      const { user, tenant,language} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/${language}/selSampleContent`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//查询自定义按钮
export const getSampleInfo2ButtonAction = createPromiseAction(
  'tenant/GET_SAMPLE2_BUTTON_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, language: string }) => pick(data, ['user', 'tenant', 'language'])
);
export function* watchSample2ButtonInfo(api) {
  yield takeLeading(getSampleInfo2ButtonAction, function* (action) {
    try {
      const { user, tenant, language} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/${language}/selSampleContent2`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//查询自定义按钮
export const getTemplateSample2InfoAction = createPromiseAction(
  'tenant/GET_SAMPLE_TEMPLATE_2_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, language: string }) => pick(data, ['user', 'tenant', 'language'])
);
export function* watchTemplateSample2Info(api) {
  yield takeEvery(getTemplateSample2InfoAction, function* (action) {
    try {
      const { user, tenant, language} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/selSample2ByTid`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 根据tenant id 查询是否开启了单点登录
export const getTenantSSOInfoAction = createPromiseAction(
  'tenant/GET_TENANT_SSO_INFO',
  data => pick(data, ['tntID']),
  data => pick(data, ['user'])
);
export function* watchTenantSSOInfo(api) {
  yield takeEvery(getTenantSSOInfoAction, function* (action) {
    try {
      // const { user, tenant, language} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${action.payload.tntID}/getSSOInfo`,
        // token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//根据团队id反查域名
//查询自定义按钮
export const getWebInfoByTenantIdAction = createPromiseAction(
  'tenant/GET_WEB_INFO_BY_TANANTID_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
export function* watchWebInfoByTenantId(api) {
  yield takeLeading(getWebInfoByTenantIdAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/selSampleByTid`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//创建样册
//查询自定义按钮
export const CreateSampleAction = createPromiseAction(
  'tenant/CREATE_SAMPLE_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant', 'language'])
);
function* watchCreateSampleInfo() {
  yield takeLeading(CreateSampleAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateSampleInfo`,
        token: user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//查询自定义按钮
export const CreateSample2Action = createPromiseAction(
  'tenant/CREATE_SAMPLE_2_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant', 'language'])
);
function* watchCreateSample2Info() {
  yield takeLeading(CreateSample2Action, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateSample2Info`,
        token: user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const CreateSample2LanguageAction = createPromiseAction(
  'tenant/CREATE_SAMPLE_2_LANGUAGE_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchSample2LanguageInfo() {
  yield takeLeading(CreateSample2LanguageAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateSample2Languages`,
        token: user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const CreateSampleLanguageAction = createPromiseAction(
  'tenant/CREATE_SAMPLE_LANGUAGE_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchSampleLanguageInfo() {
  yield takeLeading(CreateSampleLanguageAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateSampleLanguages`,
        token: user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取版本信息
export const getCheckOPSInfoAction = createPromiseAction(
  'user/CheckOPSInfo',
  (data: { payload: unknown }) => data.payload,
  (data) => pick(data, ['user', 'tenant'])
);

function* watchCheckOpsInfo(api) {
  yield takeEvery(getCheckOPSInfoAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;

      const url = `tenants/${tenant.id}/checkOpsinfo`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//更新样本通内容
export const CreateSampleContentAction = createPromiseAction(
  'tenant/CREATE_SAMPLE_CONTENT_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchSampleContentInfo() {
  yield takeLeading(CreateSampleContentAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateSampleContent`,
        token: user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//更新样本通内容
export const CreateSample2ContentAction = createPromiseAction(
  'tenant/CREATE_SAMPLE_2_CONTENT_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchSample2ContentInfo() {
  yield takeLeading(CreateSample2ContentAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateSample2Content`,
        token: user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//获取问答所有的目录
export const getQuestionsAction = createPromiseAction(
  'tenant/CREATE_SAMPLE_QUESTIONS_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
export function* watchGetQuestionsTreeInfo(api) {
  yield takeLeading(getQuestionsAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/selQuizTree`+(action.payload?.id?`?pId=${action.payload?.id}`:''),
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//获取文章所有的目录
export const getArticalsAction = createPromiseAction(
  'tenant/CREATE_SAMPLE_ARTICALS_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);
function* watchGetSampleAriticalsInfo() {
  yield takeLeading(getArticalsAction, function* (action) {
    try {
      const { user, tenant} = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/selArticleTree`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//===== 团队

//获取名片小程序码
export const getCardInfoAction = createPromiseAction(
  'tenant/GET_CARD_INFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, member: Member }) => pick(data, ['user', 'tenant', 'member'])
);
function* watchCardInfo() {
  yield takeLeading(getCardInfoAction, function* (action) {
    try {
      const { user, tenant, member } = action.meta;
      const res = yield call(api.getBlob, {
        url: `tenants/${tenant.id}/businessCard/${member.id}/wxcode`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const listTenantsAction = createPromiseAction(
  'tenant/LIST',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user'])
);
function* watchListTenants() {
  yield takeLeading(listTenantsAction, function* (action) {
    try {
      const tenants = yield call(api.getJSON, {
        url: 'tenants',
        token: action.meta.user.token,
      });
      yield call(resolvePromiseAction, action, tenants, {
        user: action.meta.user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 绑定小程序号
export const BindAppTenantAction = createPromiseAction(
  'stats/BIND_APP_VERIFY_TENANT',
  data => pick(data, ['tenantId']),
  data => pick(data, [ 'user' ]),
);
function* watchBindAppTenant() {
  yield takeLeading<AnyAction>(BindAppTenantAction, function* (action) {
    const {user: {token, user} } = action.meta;
    try {
      const res = yield call(api.postJSON, {
        url: `wechat/accreditApplicationWeixin/${action.payload.tenantId}`,
        token,
        body: {}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 校验公众号
export const VerifyTenantAction = createPromiseAction(
  'stats/OPS_VERIFY_TENANT',
  data => pick(data, ['tenantId']),
  data => pick(data, [ 'user' ]),
);
function* watchVerifyTenant() {
  yield takeLeading<AnyAction>(VerifyTenantAction, function* (action) {
    const {user: {token, user} } = action.meta;
    try {
      const res = yield call(api.postJSON, {
        url: `wechat/accreditOpenWeixin/${action.payload.tenantId}`,
        token,
        body: {}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 校验公众号
export const OpenWeChatAuthParStatusAction = createPromiseAction(
  'stats/OPS_OPEN_WECHAT_VERIFY_TENANT',
  data => pick(data, ['tenantId']),
  data => pick(data, [ 'user' ]),
);
function* watchOpenWeChatAuthParStatus() {
  yield takeLeading<AnyAction>(OpenWeChatAuthParStatusAction, function* (action) {
    const {user: {token, user} } = action.meta;
    try {
      const res = yield call(api.postJSON, {
        url: `wechat/getOpenWeChatAuthParStatus/${action.payload.tenantId}`,
        token,
        body: {}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const listMemberStatusAction = createPromiseAction(
  'tenant/LISTENMEMBERSTATUS',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser }) => pick(data, ['user'])
);
function* watchListenMemberStatus() {
  yield takeLeading(listMemberStatusAction, function* (action) {
    const {meta: { user }} = action;
    // const { meta: { user, tenant } } = action;
    try {
      const tenants = yield call(api.getJSON, {
        url: `tenants/${user.user.id}/getUserMbrStatus`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, tenants, {
        user: user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const listextraTenantsAction = createPromiseAction(
  'tenant/EXTRA_LIST',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user'])
);
function* watchExtraListTenants() {
  yield takeLeading(listextraTenantsAction, function* (action) {
    try {
      const tenants = yield call(api.getJSON, {
        url: 'tenants',
        token: action.meta.user.token,
      });
      yield call(resolvePromiseAction, action, tenants, {
        user: action.meta.user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const sendFileUrlEmailAction = createPromiseAction(
  'tenant/SEND_FILE_URL_EMAIL',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchSendFileUrlEmail() {
  yield takeLeading(sendFileUrlEmailAction, function* (action) {
    try {
      const tenants = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/sendFileUrlEmail/${action.payload.id}`,
        token: action.meta.user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, tenants, {
        user: action.meta.user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//分享单个文件
export const shareStandFileAction = createPromiseAction(
  'tenant/SHARE_STAND_FILE',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchShareStandFile() {
  yield takeLeading(shareStandFileAction, function* (action) {
    try {
      const tenants = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/saveShareFile`,
        token: action.meta.user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, tenants, {
        user: action.meta.user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getUserMbrStatusAction = createPromiseAction(
  'tenant/GET_USER_MBR_STATUS',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user','tenant'])
);
function* watchUserMbrStatus() {
  yield takeLeading(getUserMbrStatusAction, function* (action) {
    try {
      const tenants = yield call(api.getJSON, {
        url: `tenants/11111111-1111-1111-1111-111111111111/getUserMbrStatus`,
        token: action.meta.user.token,
      });
      yield call(resolvePromiseAction, action, tenants, {
        user: action.meta.user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 创建团队
export const createTenantAction = createPromiseAction(
  'tenant/CREATE',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser }) => ({ user: data.user })
);
function* watchCreateTenant() {
  yield takeLeading(createTenantAction, function* (action) {
    try {
      const tenant = yield call(api.postJSON, {
        url: 'tenants',
        token: action.meta.user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action,
        { info: {}, ...tenant},
        { user: action.meta.user.user ,notify: { message: 'Save Success' }}
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error,{notify: { message: error.message }});
    }
  });
}

export const updateTenantAction = createPromiseAction(
  'tenant/UPDATE',
  (data: { tenantId: string, data: { name: string, info: unknown } }) => pick(data, ['tenantId', 'data']),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchUpdateTenant() {
  yield takeEvery(updateTenantAction, function* (action) {
    try {
      const { data: { name, info } } = action.payload;
      const updatedTenantInfo = omitBy({ name, info }, isEmpty);
      const res = yield call(api.putJSON, {
        url: `tenants/${action.payload.tenantId}`,
        token: action.meta.user.token,
        body: updatedTenantInfo,
      });
      yield call(resolvePromiseAction, action, res, {
        user: action.meta.user.user, tenant: action.meta.tenant, notify: { message: 'Save Success' }
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//企查查接口
export const getCompanyByNameAction = createPromiseAction(
  'product/GET_COMPANY_BY_NAME',
  (data: { name?: string}) => pick(data, ['name']),
  (data:  { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
 function* watchCompanyByName(api) {
  yield takeEvery(getCompanyByNameAction, function* (action) {
    try {
      const {payload:{name}, meta: { user: { token }, tenant } } = action;
      const url = `tenants/selQccInfo`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body:{companyName:name}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 是否有编辑团队信息权限接口
export const userInfoTenant = createPromiseAction(
  'tenant/JURISDICTION',
  (data: { tenantId: string, data: { name: string, info: unknown } }) => pick(data, ['tenantId', 'data']),
  (data: { user: ContextUser, id: string }) => pick(data, ['user', 'id'])
);

function* watchUserInfo() {
  yield takeEvery(userInfoTenant, function* (action) {
    const { user, id } = action.meta
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${id}`,
        token: user.token
      });
      yield call(resolvePromiseAction, action, res, {
        user: action.meta.user.user, notify: { message: 'Save Success' }
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 切换
export const switchTenantAction = createPromiseAction(
  'tenant/SWITCH',
  (data: { payload: { id: string } }) => data.payload,
  (data: { user: ContextUser }) => ({ user: data.user })
);
function* watchSwitchTenant() {
  yield takeLeading(switchTenantAction, function* (action) {
    try {
      yield call(api.putJSON, {
        url: `tenants/${action.payload.id}/current`,
        token: action.meta.user.token
      });

      yield call(resolvePromiseAction, action, action.payload, {
        user: action.meta.user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const uploadAction = createPromiseAction(
  'tenant/UPLOAD',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
function* watchUpload(api) {
  yield takeEvery(uploadAction, function* (action) {
    try {
      const res = yield call(api.upload, {
        url: 'tenants/assets',
        token: action.meta.user.token,
        tenantId: action.meta.tenant.id,
        file: action.payload,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const uploadHeadAction = createPromiseAction(
  'tenant/HEAD_UPLOAD',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
function* watchHeadUpload(api) {
  yield takeEvery(uploadHeadAction, function* (action) {
    try {
      const res = yield call(api.upload, {
        url: 'tenants/persionUpload',
        token: action.meta.user.token,
        file: action.payload,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const axiosUploadAction = createPromiseAction(
  'tenant/AXIOS_UPLOAD',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, onProgress }) => ({ user: data.user, tenant: data.tenant, onProgress: data.onProgress })
);

function* axiosWatchUpload(api) {
  yield takeEvery(axiosUploadAction, function* (action) {
    try {
      const res = yield call(api.axiosUpload, {
        // url: 'tenants/assets',
        url: 'tenants/assets',
        token: action.meta.user.token,
        tenantId: action.meta.tenant.id,
        file: action.payload
      }, action.meta.onProgress);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const axiosUploadQuestionAction = createPromiseAction(
  'tenant/AXIOS_QUESTION_UPLOAD',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, onProgress }) => ({ user: data.user, tenant: data.tenant, onProgress: data.onProgress })
);

function* axiosWatchQuestionUpload(api) {
  yield takeEvery(axiosUploadQuestionAction, function* (action) {
    try {
      const res = yield call(api.axiosUpload, {
        // url: 'tenants/assets',
        url: `tenants/${action.meta.tenant.id}/quiz_importExcel`,
        token: action.meta.user.token,
        file: action.payload
      }, action.meta.onProgress);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const addChildShareAction = createPromiseAction(
  'showcaseEvent/ADD_CHILD_SHARE',
  ({id, docId, token}) => ({id, docId, token}),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, [ 'user', 'tenant' ]),
);
export function* watchAddChildShare(api) {
  yield takeLeading(addChildShareAction, function* (action) {
    const { payload, meta } = action;
    try {
      const arr = [];
      arr.push(payload.docId);
      const res = yield call(api.postJSON, {
        url: `tenants/${payload.id}/createAppletShare`,
        body:{documents:arr},
        token:payload.token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 定制小程序搜索文件
export const getAppletFileSearchAction = createPromiseAction(
  'applet/GET_APPLET_SEARCH',
  ({filename, appid, tenantId, languages}) => ({filename, appid, tenantId, languages}),
  (data: {user: ContextUser}) => pick(data, [ 'user' ]),
);
export function* watchAppletFileSearch(api) {
  yield takeLeading(getAppletFileSearchAction, function* (action) {
    const { payload, meta } = action;
    if (!payload.languages) payload.languages = 'zh'
    if (payload.languages === 'zh_CN') payload.languages = 'zh'
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${payload.tenantId}/selAppletDocumentsByName`,
        body: payload,
        token: meta.user.token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// 一键生成PDF  getBlob格式下载
export const getPDFBlobAction = createPromiseAction(
  'tenant/GET_PDF_BLOB',
  (data: { productId: string, language: string  }) => pick(data, ['productId', 'language']),
  (data: { user: ContextUser, tenant: Tenant }) =>  pick(data, ['user', 'tenant']),
);

function* watchGetPDFBlob(api) {
  yield takeEvery(getPDFBlobAction, function* (action) {
    // debugger
    const {payload: {productId, language}, meta: {tenant}} = action;
    // debugger
    try {
      // api.downloadPDFFile 通过BLOB形式下载
      const res = yield call(api.getJSON, {
        url: `tenants/testpdf?productId=${productId}&tenantId=${tenant.id}&language=${language}`,
        token: action.meta.user.token,
        // tenantId: action.meta.tenant.id,
        // file: action.payload
      }
      // , action.meta.onProgress
      );
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}





//获取授权状态
export const getAuthStatusAction = createPromiseAction(
  'group/GET_authauth',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
function* watchGetAuthStatus() {
  yield takeLeading(getAuthStatusAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `wechat/getAuthInfo/${action.meta.tenant.id}`,
        token: action.meta.user.token
      });


      yield call(resolvePromiseAction, action, res, {
        user: action.meta.user.user, tenant: action.meta.tenant
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//===== 用户组
export const getGroupsAction = createPromiseAction(
  'group/GET',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
function* watchGetGroups() {
  yield takeLeading(getGroupsAction, function* (action) {
    try {
      const { data, ...summary } = yield call(api.getJSON, {
        url: `tenants/${action.meta.tenant.id}/groups`,
        token: action.meta.user.token
      });
      const ids = data.map(group => group.id);
      const groups = data.reduce((prev, group) => {
        prev[group.id] = group;
        return prev;
      }, { ...summary, ids });

      yield call(resolvePromiseAction, action, groups, {
        user: action.meta.user.user, tenant: action.meta.tenant
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const searchAction = createPromiseAction(
  'group/DOC_SEARCH',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
function* watchSearch() {
  yield takeLeading(searchAction, function* (action) {
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/documents/searchFiles`,
        token: action.meta.user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 全局搜索接口
export const searchSolrAction = createPromiseAction(
  'tenant/SOLR_SEARCH',
  (data: { payload: {namefiter: string, type: number} }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
function* watchSolrSearch() {
  yield takeLeading(searchSolrAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${action.meta.tenant.id}/getFullTextSearching?nameFilter=${action.payload.namefiter}&type=${action.payload.type}`,
        // token: action.meta.user.token,
        // body: action.payload,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 收藏搜索接口
export const searchFavoriteAction = createPromiseAction(
  'tenant/FAVORITES_SEARCH',
  (data: { payload: {namefiter: string} }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
function* watchFavoriteSearch() {
  yield takeLeading(searchFavoriteAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${action.meta.tenant.id}/selCollectionsByName?name=${action.payload.namefiter}`,
        token: action.meta.user.token,
        // body: action.payload,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const addGroupAction = createPromiseAction(
  'group/ADD',
  (data: { payload: { name: string, memberIds: string[] } }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchAddGroup() {
  yield takeLeading(addGroupAction, function* (action) {
    try {
      const { id } = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/groups`,
        token: action.meta.user.token,
        body: action.payload,
      });
      const group = { id, name: action.payload.name, total: action.payload.memberIds.length };

      yield call(resolvePromiseAction, action, group, {
        user: action.meta.user.user, tenant: action.meta.tenant
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * 更新用户组。
 */
export const updateGroupAction = createPromiseAction(
  'group/UPDATE',
  (data: { payload: { id: string, group: unknown } }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchUpdateGroup() {
  yield takeLeading(updateGroupAction, function* (action) {
    try {
      yield call(api.putJSON, {
        url: `tenants/${action.meta.tenant.id}/groups/${action.payload.id}`,
        token: action.meta.user.token,
        body: action.payload.group,
      });

      yield call(resolvePromiseAction, action, action.payload, {
        user: action.meta.user.user, tenant: action.meta.tenant
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * 删除用户组。
 */
export const deleteGroupAction = createPromiseAction(
  'group/DELETE',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchDeleteGroup() {
  yield takeLeading(deleteGroupAction, function* (action) {
    try {
      yield call(api.deleteJSON, {
        url: `tenants/${action.meta.tenant.id}/groups/${action.payload}`,
        token: action.meta.user.token,
      });

      yield call(resolvePromiseAction, action, action.payload, {
        user: action.meta.user.user, tenant: action.meta.tenant
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * 获取给定团队下的用户组。
 * @param user
 * @param tenant
 */
export const useGroups = (user: User, tenant: Tenant) => useSelector(
  (state) => {
    if (has(state, [user.id, 'tenants', tenant.id, 'groups'])) {
      return state[user.id].tenants[tenant.id].groups;
    }
    return { ids: [] };
  }
);

const groupsReducer = handleActions({
  [getGroupsAction.resolved.toString()]: (state, { payload }) => {
    return payload;
  },
  [addGroupAction.resolved.toString()]: (state, { payload }) => {
    const ids = state.ids.concat([payload.id]);
    return {
      ...state,
      ids,
      [payload.id]: payload
    };
  },
  [updateGroupAction.resolved.toString()]: (state, { payload }) => {
    const group = Object.assign({}, state[payload.id], payload.group);
    return {
      ...state,
      [payload.id]: group
    };
  },
  [deleteGroupAction.resolved.toString()]: (state, { payload }) => {
    const ids = filter(state.ids, (id) => id !== payload);
    const groups = omit(state, ['ids', payload]);
    return {
      ...groups,
      ids
    };
  }
}, { ids: [] });

//===== 用户
/**
 * 获取邀请链接。
 */
export const getInvitationAction = createPromiseAction(
  'member/INVITATION_GET',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchGetInvitation() {
  yield takeLeading(getInvitationAction, function* (action) {
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/invitations`,
        token: action.meta.user.token,
        body: {}
      });

      const invitation = {
        id: res.id,
        url: api.resolveUrl(`/invitations/${res.id}`),
        wxacode: api.resolveUrl(`tenants/invitations/${res.id}/wxacode`)
      };
      yield call(resolvePromiseAction, action, invitation);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// export const getWechatAccessTokenByCode = createPromiseAction(
//   'member/GET_WECHATACCESS_TOKEN_CODE',
//   ({code}) => ({code}),
// );
// export function* watchWechatAccessToken(api) {
//   yield takeEvery(getWechatAccessTokenByCode, function* (action) {
//     try {
//       const res = yield call(api.postJSON, {
//         url: `wechat/getWechatAccessTokenByCode`,
//         body: action.payload,
//       });
//       yield call(resolvePromiseAction, action, res);
//     } catch (e) {
//       yield call(rejectPromiseAction, action, e);
//     }
//   });
// }
export const getInvitationFormAction = createPromiseAction(
  'member/INVITATION_GET_FORM',
  ({ id }) => ({ id }),
);
export function* watchGetInvitationForm(api) {
  yield takeEvery(getInvitationFormAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/invitations/${action.payload.id}`
      });
      yield call(resolvePromiseAction, action, res);
    } catch (e) {
      yield call(rejectPromiseAction, action, e);
    }
  });
}

export const getCardInfoByIdAction = createPromiseAction(
  'member/GET_CARD_INFO_BY_ID',
  ({ id }) => ({ id }),
);
export function* watchCardInfoById(api) {
  yield takeEvery(getCardInfoByIdAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${action.payload.id}/getMemberInfo`
      });
      yield call(resolvePromiseAction, action, res);
    } catch (e) {
      yield call(rejectPromiseAction, action, e);
    }
  });
}
export const getCardIdAction = createPromiseAction(
  'tenant/GET_CARD_ID',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant, member: Member }) => pick(data, ['user', 'tenant', 'member'])
);
function* watchCardId() {
  yield takeLeading(getCardIdAction, function* (action) {
    try {
      const { user, tenant, member } = action.meta;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/businessCard/${member.id}/cardid`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const acceptInvitationWithLoginedSessionAction = createPromiseAction(
  'member/ACCEPT_INVIATION_WITH_LOGINED_SESSION',
  ({ id, data }) => ({ id, data }),
  (data: { user: ContextUser }) => pick(data, 'user')
);
export function* acceptInvitationWithLoginedSession() {
  yield takeEvery(acceptInvitationWithLoginedSessionAction, function* (action) {
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/invitations/${action.payload.id}`,
        token: action.meta.user.token,
        body: action.payload.data,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      // debugger
      yield call(rejectPromiseAction, action, error, { notify: false });
      // throw error;
    }
  });
}

export const updateMemberMobileAction = createPromiseAction(
  'member/UPDATE_MEMBER_MOBILE',
  ({ memberId,newMobile,newCountryCode }) => ({ memberId,newMobile,newCountryCode }),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchupdateMemberMobile() {
  yield takeLeading(updateMemberMobileAction, function* (action) {
    try {

      yield call(resolvePromiseAction, action,
        action.payload,
        {
          user: action.meta.user.user, tenant: action.meta.tenant
        }
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const updateMemberAction = createPromiseAction(
  'member/UPDATE',
  ({ memberId, data }) => ({ memberId, data }),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchUpdateMember() {
  yield takeLeading(updateMemberAction, function* (action) {
    try {
      const {
        profile: { avatar, email, name, title, department, password } = {},
        groups, phone, countryCode, tenantsInfo, ...settings
      } = action.payload.data;

      const updatedData = omitBy({
        email, name, title, department, password,
        groups, settings, phone, countryCode, tenantsInfo,
      }, isNil);

      yield call(api.putJSON, {
        url: `tenants/${action.meta.tenant.id}/members/${action.payload.memberId}`,
        token: action.meta.user.token,
        body: { ...updatedData, avatar },
      });

      yield call(resolvePromiseAction, action,
        {
          ...updatedData,
          profile: { avatar, email, name, title, department },
          id: action.payload.memberId
        },
        {
          user: action.meta.user.user, tenant: action.meta.tenant
        }
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const changeMemberStatusAction = createPromiseAction(
  'member/CHANGE_STATUS',
  ({ memberId, status, groups = [] }) => ({ memberId, status, groups }),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
export const activateMemberAction = (user, tenant, memberId, groups) => {
  return changeMemberStatusAction({ user, tenant, memberId, status: 'active', groups });
};
export const rejectMemberAction = (user, tenant, memberId) => {
  return changeMemberStatusAction({ user, tenant, memberId, status: 'rejected' });
};
export const lockMemberAction = (user, tenant, memberId) => {
  return changeMemberStatusAction({ user, tenant, memberId, status: 'locked' });
};
export const unlockMemberAction = (user, tenant, memberId) => {
  return changeMemberStatusAction({ user, tenant, memberId, status: 'active' });
};

function* watchChangeMemberStatus() {
  yield takeLeading(changeMemberStatusAction, function* (action) {
    const { memberId, status, groups } = action.payload;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/members/${memberId}/${status}`,
        token: action.meta.user.token,
        body: { groups }
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 成员管理-删除成员
export const deleteMemberAction = createPromiseAction(
  'member/DELETE_MEMBERS',
  (data: { memberId }) => pick(data, ['memberId']),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);

export function* watchDeleteMember() {
  yield takeLeading(deleteMemberAction, function* (action) {
    const { memberId } = action.payload;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/deleteMemberByMid`,
        token: action.meta.user.token,
        body: {
          memberIds: [memberId]
        },
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//删除已拒绝
export const RemoveRejAction = createPromiseAction(
  'member/REMOVE',
  (data: { memberId: any }) =>  pick(data, ['memberId']),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchRemoveRej() {
  yield takeEvery(RemoveRejAction, function* (action) {

    const { payload: { memberId }, meta: { user: { token, user }, tenant } } = action;
    const difUrl = `tenants/${tenant.id}/member/${memberId}`;
    try {
      const res = yield call(api.deleteJSON, {
        url: difUrl,
        token
      });
      // const ids = res.data.map(member => member.id);
      // const members = res.data.reduce((prev, member) => {
      //   prev[member.id] = member;
      //   return prev;
      // }, { ids });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
/**
 * 获取指定状态或用户组下的所有用户。
 * @payload 用户状态或用户组ID
 */
export const getMembersAction = createPromiseAction(
  'member/GET',
  (data: { payload: any }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchGetMembers() {
  yield takeEvery(getMembersAction, function* (action) {
    const { payload: { type, selinfo }, meta: { user: { token, user }, tenant } } = action;
    const difUrl = selinfo ? `tenants/${tenant.id}/members?type=${type}&selinfo=${selinfo}` : `tenants/${tenant.id}/members?type=${type}`;
    try {
      const res = yield call(api.getJSON, {
        url: difUrl,
        token
      });
      const ids = res.data.map(member => member.id);
      const members = res.data.reduce((prev, member) => {
        prev[member.id] = member;
        return prev;
      }, { ids });
      yield call(resolvePromiseAction, action, members, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
/**
 * @param user
 * @param tenant
 * @param payload id: member id
 */
export const getOneMemberAction = createPromiseAction(
  'members/GET_ONE',
  ({ payload }) => payload,
  ({ user, tenant }) => ({ user, tenant })
);
function* watchGetOneMember(api) {
  yield takeLeading(getOneMemberAction, function* (action) {
    const { payload: { id }, meta: { user, tenant } } = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/members/${id}`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getHasEmailAction = createPromiseAction(
  'tenant/CheckTenantInfo',
  ({ payload }) => payload,
  ({ user, tenant }) => ({ user, tenant })
);
export function* watchCheckInfo(api) {
  yield takeLeading(getHasEmailAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/checkTenantInfo`,
        token: user?.token,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 专属小程序获取资料 ？
export const getAppletDocumentsAction = createPromiseAction(
  'user_share/GET_APPLET_DOCUMENTS',
  ({ payload }) => payload,
);
export function* watchGetAppletDocuments(api) {
  yield takeLeading(getAppletDocumentsAction, function* (action) {
    const { payload: { appid, id, languages } } = action;
    var lang = languages || 'zh'
    if (languages && languages === 'zh_CN') { lang = 'zh' }
    try {
      const res = yield call(api.postJSON, {
        url: isEmpty(id) ? `tenants/${appid}/getAppletDatabase` : `tenants/getAppletDatabase/${id}`,
        body: { languages: lang }
      });
      const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, docs);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// ABB elibrary开启情况查询
export const getABBStatus = createPromiseAction(
  'tenant/GET_ABB_STATUS',
  data => pick(data, ['tenantId']),
  data => pick(data, [ 'user' ]),
);
function* watchGetABBStatus() {
  yield takeLeading<AnyAction>(getABBStatus, function* (action) {
    const {user: {token, user} } = action.meta;
    try {
      const res = yield call(api.getJSON, {
        url: `sys/${action.payload.tenantId}/selTenantsMetadata`,
        token,
        body: {}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


const membersReducer = handleActions({
  [getMembersAction.resolved.toString()]: (state, { payload }) => {
    return {
      ...state,
      ...omit(payload, 'ids'),
    };
  },
  [updateMemberMobileAction.resolved.toString()]:(state, { payload })=>{
    const oldMember = state[payload.memberId];

    return {
      ...state,
      [payload.memberId]: mergeWith(
        {}, oldMember, {phone:payload.newMobile,countryCode:payload.newCountryCode}
            )
        };
  },
  [getOneMemberAction.resolved.toString()]: (state, { payload }) => {
    return { ...state, [payload.id]: payload };
  },
  [updateMemberAction.resolved.toString()]: (state, { payload }) => {
    const oldMember = state[payload.id];
    return {
      ...state,
      [payload.id]: mergeWith(
        {}, oldMember, payload,
        (oldObj, newObj) => { if (Array.isArray(newObj)) { return newObj; } }
      )
    };
  }
}, {});

export const useMember = (user: User, tenant: Tenant, memberId): Member => useSelector(
  (state) => {
    return get(state, [user?.id, 'tenants', tenant?.id, 'members', memberId]);
  }
);
export const useMembers = ({ user, tenant }) => useSelector(
  state => get(state, [user.user?.id, 'tenants', tenant?.id, 'members'], {})
);

export const getWXCodeAction = createPromiseAction(
  'showcase/GET_WXCODE',
  ({ showcaseId }) => ({ showcaseId }),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchGetWXCode() {
  yield takeLeading(getWXCodeAction, function* (action) {
    const { payload: { showcaseId }, meta: { user: { token }, tenant } } = action;
    try {
      const res = yield call(api.getBlob, {
        url: `tenants/${tenant.id}/showcases/${showcaseId}/wxcode`,
        token,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * 专属小程序
 */
// 查询专属小程序全部信息？
export const getAppletMessageAction = createPromiseAction(
  'applet/GET_APPLEAT',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchGetApplet() {
  yield takeLeading(getAppletMessageAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/${action.payload}/selAppletSlideshow`,
        token: user.token
      });
      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取多语言信息
export const getAppletLanguageAction = createPromiseAction(
  'applet/GET_LANGUAGES',
  () => null,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchGetAppletLanguage() {
  yield takeLeading(getAppletLanguageAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/selLanguages`,
        token: user.token
      });
      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 获取多语言信息
export const getMAppletLanguageAction = createPromiseAction(
  'applet/GET_MLANGUAGES',
  ({ payload }) => payload
);
export function* watchGetMAppletLanguage(api) {
  yield takeLeading(getMAppletLanguageAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${action.payload.id}/selLanguages`
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 根据 appid 获取 tenantid
export const getTenantIdAction = createPromiseAction(
  'applet/GET_TENANTID_ACTION',
  (data: {appid: string}) => pick(data, ['appid'])
);
export function* watchTenantIdByAppid(api) {
  yield takeLeading(getTenantIdAction, function* (action) {
    try {
      const home = yield call(api.getJSON, {
        url: `tenants/${action.payload.appid}/selTenantidByAppid`,
      });
      yield call(resolvePromiseAction, action, home);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const bindApplicationAction = createPromiseAction(
  'applet/BIND_APPLICATION_ACTION',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchBindApplication() {
  yield takeLeading(bindApplicationAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `wechatPlatform/openWeChatApplicationAuthPar/${tenant.id}/${action.payload.appid}?auth_code=${action.payload?.auth_code}`,
        token: user.token,
      });
      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 增加多语言信息
export const postAppletLanguageAction = createPromiseAction(
  'applet/POST_LANGUAGES',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchAppletLanguage() {
  yield takeLeading(postAppletLanguageAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateLanguages`,
        token: user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 删除多语言信息
export const deleteAppletLanguageAction = createPromiseAction(
  'applet/DELETE_LANGUAGES',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchDeleteLanguage() {
  yield takeLeading(deleteAppletLanguageAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.deleteJSON, {
        url: `tenants/${tenant.id}/${action.payload}/deleteLanguages`,
        token: user.token
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 上传banner图等
export const postAppletSlideshowAction = createPromiseAction(
  'applet/POST_SLIDESHOW',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchAppletSlidesshow() {
  yield takeLeading(postAppletSlideshowAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateAppletSlideshow`,
        token: user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 上传资料库
export const postAppletDatabaseAction = createPromiseAction(
  'applet/POST_DATABASE',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchAppletDatabase() {
  yield takeLeading(postAppletDatabaseAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/saveAppletDatabase`,
        token: user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 上传对外资料库
export const postOutLibDatabaseAction = createPromiseAction(
  'applet/POST_OUT_LIB_DATABASE',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchOutLibDtabase() {
  yield takeLeading(postOutLibDatabaseAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/updateExtDocuments`,
        token: user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 上传访客需要提交的信息
export const postAppletVisitorinfoAction = createPromiseAction(
  'applet/POST_VISITORINFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchAppletVisitorinfo() {
  yield takeLeading(postAppletVisitorinfoAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/saveAppletVisitorInfo`,
        token: user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// 上传对外资料库访客需要提交的信息
export const postOutLibVisitorinfoAction = createPromiseAction(
  'applet/POST_OUTLIB_VISITORINFO',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchOutLibVisitorinfo() {
  yield takeLeading(postOutLibVisitorinfoAction, function* (action) {
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/saveExternalVisitorInfo`,
        token: user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 上传触发时机
export const postAppletEoiAction = createPromiseAction(
  'applet/POST_EOI',
  (data) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchAppletEoi() {
  yield takeLeading(postAppletEoiAction, function* (action) {
    const { payload, meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/saveAppletEoi`,
        token: user.token,
        body: { tenantId: tenant.id, ...payload },
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 获取分享单页图片
export const getPdfStandImageAction = createPromiseAction (
  'user_share/Get_PDF_STAND_Image',
  (data: {id?: string,tenantId?: string,pageIndex?: string}) => pick(data, ['id','tenantId','pageIndex']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* getPdfStandImage() {
  yield takeEvery(getPdfStandImageAction, function*(action) {
    const {payload: {id,tenantId,pageIndex}, meta: {user: {token, user}, tenant}} = action;

    try {
      const res = yield call(api.postJSON, {
        url: `tenants/pdfImageByPage`,
        body:{id,tenantId,pageIndex

        }
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }

  });
}
// 上传对外资料库触发时机
export const postOutLibEoiAction = createPromiseAction(
  'applet/POST_OUTLIB_EOI',
  (data) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchOutLibEoi() {
  yield takeLeading(postOutLibEoiAction, function* (action) {
    const { payload, meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/saveExternalEoi`,
        token: user.token,
        body: { tenantId: tenant.id, ...payload },
      });

      yield call(resolvePromiseAction, action, res, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}



/**
 * 获取团队leads 列表
 */
//TODO增加筛选条件
export const getTenantLeadsAction = createPromiseAction(
  'leads/LIST_BY_TENANT',
  (data) => pick(data, ['size', 'skip', 'sortModel', 'filterValue', 'page', 'date']),
  data => pick(data, ['user', 'tenant'])
);
export function* watchGetTenantLeads() {
  yield takeLeading<AnyAction>(getTenantLeadsAction, function* (action) {
    // sortModel[0] field sort
    const sorts = action.payload.sortModel;
    // sorts.length > 0 ? [{"column": sorts.field, "sort": sorts.sort}] : []
    // filterValue.columnField operatorValue value
    const filters = action.payload.filterValue;
    // filters.hasOwnProperty('value') ? [{"column": filters.columnField, "method": filters.operatorValue, "value": filters.value}] : []
    let dataobj = {
      // startDate: action.payload.date[0].toISOString(),
      // endDate: action.payload.date[1].toISOString(),
      size: action.payload.size.toString(),
      offset: action.payload.page.toString(),
      // sorted?: [],
      // condition?: []
    };
    if(action.payload.date?.[0]){
      dataobj['startDate'] = action.payload.date[0].toISOString()
    }
    if(action.payload.date?.[1]){
      dataobj['endDate'] = action.payload.date[1].toISOString()
    }
    if(action.payload.date?.[2]){
      dataobj['startDateStats'] = action.payload.date[2].toISOString()
    }
    if(action.payload.date?.[3]){
      dataobj['endDateStats'] = action.payload.date[3].toISOString()
    }
    if (sorts !== undefined) {
      sorts.length > 0 ? dataobj.sorted = [{ 'column': sorts[0].field, 'sort': sorts[0].sort }] : '';
    }
    filters !== undefined && filters.hasOwnProperty('value') ? dataobj.condition = [{ 'column': filters.columnField, 'method': filters.operatorValue, 'value': filters.value }] : '';
    const { meta: { user, tenant, dateRange, sortModel } } = action;

    try {
      const res = yield call(api.postJSON, {
        url: `stats/${tenant.id}/stats/leads`,
        body: dataobj,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
//数据统计-成员-table
export const getTenantMemberStatsAction = createPromiseAction(
  'stats/LIST_BY_TENANT_MEMBER',
  (data) => pick(data, ['size', 'skip', 'sortModel', 'filterValue', 'page', 'date']),
  data => pick(data, ['user', 'tenant'])
);
export function* watchGetTenantMemberStats() {

  yield takeLeading<AnyAction>(getTenantMemberStatsAction, function* (action) {
    // sortModel[0] field sort
    const sorts = action.payload.sortModel;
    // sorts.length > 0 ? [{"column": sorts.field, "sort": sorts.sort}] : []
    // filterValue.columnField operatorValue value
    const filters = action.payload.filterValue;
    // filters.hasOwnProperty('value') ? [{"column": filters.columnField, "method": filters.operatorValue, "value": filters.value}] : []
    let dataobj = {
      // startDate: action.payload.date[0].toISOString(),
      // endDate: action.payload.date[1].toISOString(),
      size: action.payload.size.toString(),
      offset: action.payload.page.toString(),
      // sorted?: [],
      // condition?: []
    };
    if(action.payload.date?.[0]){
      dataobj['startDate'] = action.payload.date[0].toISOString()
    }
    if(action.payload.date?.[1]){
      dataobj['endDate'] = action.payload.date[1].toISOString()
    }
    if(action.payload.date?.[2]){
      dataobj['startDateStats'] = action.payload.date[2].toISOString()
    }
    if(action.payload.date?.[3]){
      dataobj['endDateStats'] = action.payload.date[3].toISOString()
    }
    if (sorts !== undefined) {
      sorts.length > 0 ? dataobj.sorted = [{ 'column': sorts[0].field, 'sort': sorts[0].sort }] : '';
    }
    filters !== undefined && filters.hasOwnProperty('value') ? dataobj.condition = [{ 'column': filters.columnField, 'method': filters.operatorValue, 'value': filters.value }] : '';
    const { meta: { user, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `stats/${tenant.id}/members`,
        // body: {
        //   startDate: action.payload.data[0].toISOString(),
        //   endDate: action.payload.data[1].toISOString(),
        //   size: action.payload.size.toString(),
        //   offset: action.payload.skip.toString(),
        // },
        body: dataobj,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
//数据统计-活动-table
export const getTenantCampaignStatsAction = createPromiseAction(
  'stats/LIST_BY_TENANT_CAMPAIGN',
  (data) => pick(data, ['size', 'skip', 'sortModel', 'filterValue', 'page', 'date']),
  data => pick(data, ['user', 'tenant'])
);
export function* watchGetTenantCampaignStats() {
  yield takeLeading<AnyAction>(getTenantCampaignStatsAction, function* (action) {
    const sorts = action.payload.sortModel;
    // sorts.length > 0 ? [{"column": sorts.field, "sort": sorts.sort}] : []
    // filterValue.columnField operatorValue value
    const filters = action.payload.filterValue;
    // filters.hasOwnProperty('value') ? [{"column": filters.columnField, "method": filters.operatorValue, "value": filters.value}] : []
    let dataobj = {
      // startDate: action.payload.date[0].toISOString(),
      // endDate: action.payload.date[1].toISOString(),
      size: action.payload.size.toString(),
      // offset: action.payload.skip.toString(),
      offset: action.payload.page.toString(),
      // sorted?: [],
      // condition?: []
    };
    if(action.payload.date?.[0]){
      dataobj['startDate'] = action.payload.date[0].toISOString()
    }
    if(action.payload.date?.[1]){
      dataobj['endDate'] = action.payload.date[1].toISOString()
    }
    if(action.payload.date?.[2]){
      dataobj['startDateStats'] = action.payload.date[2].toISOString()
    }
    if(action.payload.date?.[3]){
      dataobj['endDateStats'] = action.payload.date[3].toISOString()
    }
    if (sorts !== undefined) {
      sorts.length > 0 ? dataobj.sorted = [{ 'column': sorts[0].field, 'sort': sorts[0].sort }] : '';
    }
    filters !== undefined && filters.hasOwnProperty('value') ? dataobj.condition = [{ 'column': filters.columnField, 'method': filters.operatorValue, 'value': filters.value }] : '';


    const { meta: { user, tenant, dateRange } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `stats/${tenant.id}/campaign_List`,
        body: dataobj,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
/**
 * 团队成员获取的线索列表
 */
export const getLeadsAction = createPromiseAction(
  'leads/LIST',
  () => null,
  ({ user, tenant }) => ({ user, tenant })
);

export function* watchGetLeads() {
  yield takeLeading(getLeadsAction, function* (action) {
    try {
      const { meta: { user: { token, user }, tenant } } = action;
      const list = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/members/${user.id}/leads`,
        token,
      });

      const leads = yield sanitize.leadList(list);
      yield call(resolvePromiseAction, action, leads, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const useLeads = ({ user: { user }, tenant }) => useSelector(
  (state) =>
    get(state, [user.id, 'tenants', tenant.id, 'leads'])
);

const leadsReducer = handleActions({
  [getLeadsAction.resolved.toString()]: (_state, { payload }) => {
    const ids = payload.map(item => item.userId);
    const data = zipObject(ids, payload);
    return { ids, data };
  },
  [getShareLeadAction.resolved.toString()]: (_state, { payload }) => {
    const ids = _state.ids.includes(payload.id)
      ? _state.ids
      : [].concat(_state.ids).concat(payload.id);

    return {
      ids,
      data: Object.assign({}, _state.data, { [payload.id]: payload })
    };
  }
}, { ids: [], data: {} });

//===== lead events
export const getLeadEventsAction = createPromiseAction(
  'lead_events/GET_LIST',
  (data: { payload: unknown }) => data.payload,
  ({ user, tenant }) => ({ user, tenant })
);
export function* watchGetLeadEvents() {
  yield takeLeading(getLeadEventsAction, function* (action) {
    try {
      const leads = yield call(api.getJSON, {
        url: `tenants/lead/${action.meta.tenant.id}/events/${action.payload}`,
        token: action.meta.user.token,
      });

      yield call(resolvePromiseAction, action, leads);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getUpdateAppletLinkAction = createPromiseAction(
  'lead_events/GET_WX_APP_LINK',
  (data: { payload: unknown }) => data.payload,
  ({ user, tenant }) => ({ user, tenant })
);
export function* watchUpdateAppletLinkEvents() {
  yield takeLeading(getUpdateAppletLinkAction, function* (action) {
    try {
      const leads = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/updateAppletLink`,
        token: action.meta.user.token,
        body:action.payload

      });

      yield call(resolvePromiseAction, action, leads);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 定制小程序获取首页轮播图 ？
export const getHomeFileAction = createPromiseAction(
  'applet/GET_HOME_ACTION',
  ({ payload }) => payload,
);
export function* watchGetHomeFile(api) {
  yield takeLeading(getHomeFileAction, function* (action) {
    const { payload: { id, languages }} = action;
    var lang = languages || 'zh'
    if (languages && languages === 'zh_CN') { lang = 'zh' }
    try {
      const home = yield call(api.postJSON, {
        url: `tenants/${action.payload.id}/${lang}/selAppletSlideshow`,
      });
      yield call(resolvePromiseAction, action, home);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//===== receive showcases
export const receiveShowcaseAction = createPromiseAction(
  'showcase/GET',
  (data: { id: string }) => pick(data, ['id']),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user'])
);
export function* watchGetShowcase(api) {
  yield takeLeading(receiveShowcaseAction, function* (action) {
    try {
      const showcase = yield call(api.getJSON, {
        url: `tenants/showcases/${action.payload.id}`
      });
      yield call(resolvePromiseAction, action, showcase);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const receiveShowcaseDocumentAction = createPromiseAction(
  'showcase/GET_DOCUMENT',
  (data: { id: string, showcaseId: string }) => pick(data, ['showcaseId', 'id']),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
export function* watchGetShowcaseDocuments(api) {
  yield takeEvery(receiveShowcaseDocumentAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/showcases/${action.payload.showcaseId}/documents/${action.payload.id}`
      });

      yield call(resolvePromiseAction, action, res, {
        ...action.payload, ...action.meta, user: action.meta.user?.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const createShowcaseEventAction = createPromiseAction(
  'showcaseEvent/POST',
  ({ documentId, showcaseId, tenantId, type }) => ({ documentId, showcaseId, tenantId, type }),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user']),
);

export function* watchCreateShowcaseEvent(api) {
  yield takeLeading(createShowcaseEventAction, function* (action) {
    const { payload, meta } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/showcases/${payload.showcaseId}/events`,
        body: {
          openId: meta.user?.openid,
          userId: meta.user?.user?.id, ...payload
        },
        token: meta.user?.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// TODO 这地方等完善需求再改
export const createShowcaseLikeAction = createPromiseAction(
  'showcaseLike/POST',
  ({ showcaseId, documentId, tenantId }) => ({ showcaseId, documentId, tenantId }),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant']),
);

export function* watchCreateShowcaseLike(api) {
  yield takeLeading(createShowcaseLikeAction, function* (action) {
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/showcases/${action.payload.showcaseId}/like`,
        body: {
          openId: action.meta.user?.openid,
          userId: action.meta.user?.user?.id,
          ...action.payload
        },
        token: action.meta.user?.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * @deprecated
 */
export const useReceiveShowcase = (id: string): Share => useSelector(state => {
  return get(state, ['receiveShowcases', id, 'showcase']);
});

/**
 * @deprecated
 */
export const useReceiveShowcaseDocuments = (id: string) => useSelector(state => {
  return get(state, ['receiveShowcases', id, 'documents'], {});
});



//===== user showcases
/**
 * 获取用户自己的分享列表，
 * @todo 需要确定 rename 为 useShowcases
 * @deprecated
 */
export const useUserShowcases = (user, tenant) => useSelector(
  (state) => {
    if (has(state, [user.id, 'tenants', tenant.id, 'showcases'])) {
      const showcases = state[user.id].tenants[tenant.id].showcases;
      const userShowcases = filter(mapValues(showcases, 'showcase'), { memberId: user.id })
      .map(showcase => ({
        ...showcase,
        stats: showcases[showcase.id].stats
      }));
      return userShowcases;
    }
    return undefined;
  }
);
export const useShowcaseLeads = (user, tenant) => useSelector((state) => {
  return get(state, [user.id, 'tenants', tenant.id, 'leads'], {});
});
/**
 * @deprecated
 */
export const useShowcaseDocuments = (user, tenant, showcaseId) => useSelector((state) => {
  return get(state, [user.id, 'tenants', tenant.id, 'showcases', showcaseId, 'documents'], {});
});
//===== 帐号和统计
export const useAccount = (user, tenant) => useSelector(
  (state) => {
    // TODO
    return get(state, [user.id, 'tenants', tenant.id, 'account'], {
      quota: {
        storage: 1000000000,
        netout: 1000000000000,
        leads: 500,
      }
    });
  }
);
export const useTenant = (user: User, tenantId: string) => {
  return useSelector((state) => get(state, [user?.id, 'tenants', tenantId, 'tenant']));
};

export const getAccountAction = createPromiseAction(
  'account/GET',
  () => ({}),
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
function* watchGetAccount(api) {
  yield takeLeading(getAccountAction, function* (action) {
    const { user, tenant } = action.meta;
    try {
      const account = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/account`,
        token: user.token,
      });

      yield call(resolvePromiseAction, action, account, { user: user.user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}



/**
 * @deprecated
 */
export const receiveShowcaseReducer = handleActions({
  [receiveShowcaseAction.resolved.toString()]: (state, { payload }) => {
    return { ...state, [payload.id]: { showcase: payload, documents: {} } };
  },
  [receiveShowcaseDocumentAction.resolved.toString()]: (state, { meta, payload }) => {
    if (!meta) { return state; }
    const sid = meta.showcaseId;
    const documentId = meta.id;
    if (!state[sid]) {
      return state;
    }

    return {
      ...state,
      [sid]: {
        ...state[sid],
        documents: { ...state[sid].documents, [documentId]: payload }
      }
    };
  }
}, {});

//===== Reducer
const tenantActionsReducer = handleActions({
  [listTenantsAction.resolved.toString()]: (_state, { payload }) => {
    const tenants = payload.reduce((prev, tenant) => {
      prev[tenant.id] = { ...prev[tenant.id], tenant };
      return prev;
    }, {});
    return tenants;
  },
  [createTenantAction.resolved.toString()]: (state, { payload }) => {
    // XXX
    const tenant = Object.assign({ info: {} }, payload);
    return {
      ...state,
      [payload.id]: { tenant }
    };
  },
  [switchTenantAction.resolved.toString()]: (state, { payload }) => {
    return { ...state, [payload.id]: { ...state[payload.id], tenant: payload } };
  },
  [updateTenantAction.resolved.toString()]: (state, { payload }) => {
    const newTenant = { ...get(state, [payload.id, 'tenant']), ...payload };
    return {
      ...state,
      [payload.id]: {
        ...state[payload.id],
        tenant: newTenant
      }
    };
  },
  [getAccountAction.resolved.toString()]: (state, { meta: { tenant: { id: tid } }, payload }) => {
    const tenant = state[tid];
    return {
      ...state,
      [tid]: {
        ...tenant,
        account: payload,
      }
    };
  },
}, {});

/*
  汇总所有的reducer
*/
const combinedTenantReducer = combineReducers({
  documents: documentsReducer,
  groups: groupsReducer,
  shares: shareReducer,
  members: membersReducer,
  campaigns: campaignReducer,
  leads: leadsReducer,
  favorites: favoriteReducer,
  stats: statsReducer,
  products: productCenterReducer,
  outLibDocs:outLibReducer,
  library: libraryReducer,
});

export const tenantReducer = (state = {}, action) => {
  const tenantsState = tenantActionsReducer(state, action);
  if (has(action, 'meta.tenant.id') && tenantsState[action.meta.tenant.id]) {
    const tenantState = omit(tenantsState[action.meta.tenant.id], 'tenant');
    const detail = combinedTenantReducer(tenantState, action);
    tenantsState[action.meta.tenant.id] = Object.assign({}, tenantsState[action.meta.tenant.id], detail);
  }
  return tenantsState;
};

export function* tenantSaga(providedApi): Generator {
  api = providedApi;
  yield all([
    // call(pdfImageList),
    call(watchSendFileUrlEmail),
    call(getPdfStandImage),

    call(watchShareStandFile),
    call(watchUserMbrStatus),
    call(watchListTenants),
    call(watchListenMemberStatus),
    call(watchVerifyTenant),
    call(watchBindAppTenant),
    call(watchOpenWeChatAuthParStatus),
    call(watchExtraListTenants),
    call(watchCreateTenant),
    call(watchCardInfo),
    call(watchCardId),
    call(watchSwitchTenant),
    call(watchUpdateTenant),
    call(watchUserInfo),
    call(watchCompanyByName, providedApi),
    call(watchGetOneMember, providedApi),
    call(watchGetAppletDocuments, providedApi),
    call(watchGetHomeFile, providedApi),

    call(watchCheckInfo,providedApi),
    call(watchGetMembers),
    call(watchRemoveRej),
    call(watchChangeMemberStatus),
    call(watchDeleteMember),
    call(watchUpdateMember),
    call(watchupdateMemberMobile),
    call(watchGetGroups),
    call(watchGetAuthStatus),
    call(watchAddGroup),
    call(watchUpdateGroup),
    call(watchDeleteGroup),
    call(watchGetInvitation),
    call(watchSearch),
    call(watchUpload, providedApi),
    call(axiosWatchUpload, providedApi),
    call(axiosWatchQuestionUpload,providedApi),
    call(watchGetPDFBlob, providedApi),    //  下载pdf
    call(watchCheckOpsInfo, providedApi),
    call(watchAddChildShare, providedApi),
    call(watchAppletFileSearch, providedApi),
    call(watchSampleInfo),
    call(watchSample2Info,providedApi),
    call(getTemplateInfo),
    call(watchSampleButtonInfo),
    call(watchWebInfoByTenantId, api),
    call(watchCreateSampleInfo),
    call(watchCreateSample2Info),
    call(watchSampleLanguageInfo),
    call(watchSample2LanguageInfo),
    call(watchGetSampleAriticalsInfo),
    call(watchGetQuestionsTreeInfo, api),
    call(watchSampleContentInfo),
    call(watchSample2ContentInfo),
    // call(watchTenantSSOInfo),
    // call(watchWebInfoByTenantId, api),
    ...documentWatchers.map((fn) => call(fn, providedApi)),
    ...shareWatchers.map((fn) => call(fn, providedApi)),
    ...campaignWatchers.map((fn) => call(fn, providedApi)),
    ...favoriteWatchers.map((fn) => call(fn, providedApi)),
    ...statsWatchers.map((fn) => call(fn, providedApi)),
    ...productCenterWatchers.map((fn) => call(fn, providedApi)),
    ...outLibWatchers.map((fn) => call(fn, providedApi)),
    ...libraryWatchers.map((fn) => call(fn, providedApi)),

    call(watchGetWXCode),

    call(watchGetInvitation), call(acceptInvitationWithLoginedSession),

    call(watchGetAccount, providedApi),
    call(watchTenantIdByAppid, providedApi),

    call(watchGetApplet),
    call(watchGetAppletLanguage),
    call(watchGetMAppletLanguage,providedApi),
    call(watchAppletLanguage),
    call(watchBindApplication),
    call(watchDeleteLanguage),
    call(watchAppletSlidesshow),
    call(watchAppletDatabase),
    call(watchOutLibDtabase),
    call(watchAppletVisitorinfo),
    call(watchAppletEoi),
    call(watchOutLibVisitorinfo),
    call(watchOutLibEoi),
    call(watchGetLeads), call(watchGetLeadEvents), call(watchGetTenantLeads),call(watchUpdateAppletLinkEvents),
    call(watchGetTenantMemberStats), call(watchGetTenantCampaignStats),
    call(watchGetABBStatus),
    call(watchSolrSearch),
    call(watchFavoriteSearch),
    // call(watchCatlogLanguage, api),
  ]);
}
